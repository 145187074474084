.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  font-family: inherit;
}
.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid transparent;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #00a1c9;
}
