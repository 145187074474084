@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media all {
  /*.print-content {*/
  /*  display: none;*/
  /*}*/
  .invoice-box {
    border-style: inset;
    border-color: #333;
  }
}

@media print {
  .invoice-box {
    break-before: page;
    border: none;
  }

  .print-content {
    display: block;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
  }

  html,
  body {
    width: 210mm;
    height: 280mm;
  }

  footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}

footer {
  display: none;
}
.invoice-box {
  max-width: 840px;
  margin: auto;
  padding-top: 10px;
  line-height: 24px;
  word-spacing: 2px;
  color: #333;
}

.page-header,
.page-header-space {
  height: 100px;
}

.page-footer,
.page-footer-space {
  height: 50px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-header {
  /*position: fixed;*/
  /*top: 0mm;*/
  width: 100%;
}

.border-right {
  border-right: 2px solid #cccccc;
}

.padding-left-20 {
  padding-left: 20px;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  font-size: 12pt;
  padding: 10px;
  vertical-align: top;
}

.align-right {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table thead.heading td {
  background: #eee;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:last-child {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
